import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = () => (
  <Container fluid className="vh-75 d-flex">
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="dark" type="grow" size="sm" className="mr-2" />
    </Row>
  </Container>
);

export default Loader;
