import React from "react";
import { Link } from "react-router-dom";



const Privacy = () => (
  <div>
    <p>
      Privacy Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
      commodo ligula eget dolor tellus eget condimentum rhoncus. Aenean
      massa. Cum sociis natoque penatibus et magnis neque dis parturient
      montes, nascetur ridiculus mus.
    </p>
    <p>
      Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
      sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
      vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
      imperdiet a, venenatis vitae, justo.
    </p>
  </div>
);

export default Privacy;
